
import JigActions from '@/components/datatable/JigActions.vue'
import UserAvatar from '@/components/image/UserAvatar.vue'
import JigTooltip from '@/components/input/JigTooltip.vue'
import SearchField from '@/components/input/SearchField.vue'
import TeamJigsFilter from '@/components/input/TeamJigsFilters.vue'
import DataTable from '@/components/material/DataTable.vue'
import MoreActions from '@/components/material/MoreActions.vue'
import JigActionModals from '@/components/modals/JigActionModals.vue'
import ShareableLink from '@/components/share/ShareableLink.vue'
import IconUsers from '@/components/svg/IconUsers.vue'
import { JigConst, TenantConst, UtilsConst } from '@/constants'
import FiltersMixin from '@/mixin/Filters'
import JigPermissions from '@/mixin/JigPermissions'
import JigsFiltersMixin from '@/mixin/JigsFilters'
import { DataTableHeadings } from '@/modules/library/types'
import { Tenant, TenantUser } from '@/store/modules/app/types'
import { JigMetadata, JigVisibilityEventData, LoadTenantJigsPayload, Permission, TenantJigPermissions } from '@/store/modules/jig/types'
import { Namespace, StandardObject } from '@/store/types'
import { DataTableConfig } from '@/utils/data-table-helper'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { cloneDeep } from 'lodash'
import { mixins } from 'vue-class-component'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation, State } from 'vuex-class'

// Define a type that maps keys to property names
type JigsLoadConfigKey = 'teamJigsLoadConfig' | 'myJigsLoadConfig'

@Component({
  components: {
    'jig-action-modals': JigActionModals,
    'jig-actions': JigActions,
    'jig-tooltip': JigTooltip,
    'team-jigs-filters': TeamJigsFilter,
    'search-field': SearchField,
    'shareable-link': ShareableLink,
    'data-table': DataTable,
    'more-actions': MoreActions,
    'user-avatar': UserAvatar,
    'icon-users': IconUsers,
  },
})
export default class JigsListingVue extends mixins(FiltersMixin, JigsFiltersMixin, JigPermissions) {
  @State('initializing', { namespace: Namespace.Utils })
  public initializing!: boolean
  @State('myJigs', { namespace: Namespace.Jig })
  public myJigs!: JigMetadata[]
  @State('teamJigs', { namespace: Namespace.Jig })
  public teamJigs!: JigMetadata[]
  @State('jigDetailPermissions', { namespace: Namespace.Jig })
  public jigDetailPermissions!: Permission[]
  @State('myJigsItemsPerPage', { namespace: Namespace.Utils })
  public myJigsItemsPerPage!: number
  @State('teamJigsItemsPerPage', { namespace: Namespace.Utils })
  public teamJigsItemsPerPage!: number
  @State('uid', { namespace: Namespace.App })
  public uid!: number
  @State('myJigCount', { namespace: Namespace.Jig })
  public myJigCount!: number
  @State('myTenantPrivateJigCount', { namespace: Namespace.Jig })
  public myTenantPrivateJigCount!: number
  @State('myTenantSharedJigCount', { namespace: Namespace.Jig })
  public myTenantSharedJigCount!: number
  @State('myTenantTotalJigCount', { namespace: Namespace.Jig })
  public myTenantTotalJigCount!: number
  @State('jigListTotalPages', { namespace: Namespace.Jig })
  public jigListTotalPages!: number
  @State('myJigsListCurrentPage', { namespace: Namespace.Jig })
  public myJigsListCurrentPage!: number
  @State('teamJigsListCurrentPage', { namespace: Namespace.Jig })
  public teamJigsListCurrentPage!: number

  @Getter('isPreparingApp', { namespace: Namespace.Utils })
  public isPreparingApp!: boolean

  @Mutation('updateMyJigsItemsPerPage', { namespace: Namespace.Utils })
  public updateMyJigsItemsPerPage: any
  @Mutation('updateTeamJigsItemsPerPage', { namespace: Namespace.Utils })
  public updateTeamJigsItemsPerPage: any
  @Mutation('updateMyJigsListCurrentPage', { namespace: Namespace.Jig })
  public updateMyJigsListCurrentPage: any
  @Mutation('updateTeamJigsListCurrentPage', { namespace: Namespace.Jig })
  public updateTeamJigsListCurrentPage: any

  @Action('loadMyJigs', { namespace: Namespace.Jig })
  public loadMyJigs: any
  @Action('loadTeamJigs', { namespace: Namespace.Jig })
  public loadTeamJigs: any
  @Action('loadJigPermissions', { namespace: Namespace.Jig })
  public loadJigPermissions: any

  public $refs!: Vue['$refs'] & {
    jigActionModals: JigActionModals
    jigListing: DataTable
    teamJigsFilter: TeamJigsFilter
    [key: string]: Vue | Element | Vue[] | Element[] | undefined
  }

  private isPreparingData: boolean = false
  private selectedJigId: string | '' = ''
  private selectedJigName: string = ''
  private searchCriteria: string = ''
  private permissionsDirty: boolean = false
  private jigListHeadings: DataTableHeadings = {}
  private jigListShareHeaders: DataTableHeadings = cloneDeep(DataTableConfig.jigsList.headers.shared)
  private teamJigsOnlyHeaders: DataTableHeadings = cloneDeep(DataTableConfig.jigsList.headers.teamJigs)
  private jigListActionHeader: any = {
    action: {
      copy: ' ',
      sort: false,
    },
  }
  private jigListItemsPerPageOptions = DataTableConfig.jigsList.itemsPerPageOptions
  private myJigsLoadConfig: LoadTenantJigsPayload = cloneDeep(DataTableConfig.jigsList.loadConfig.my)
  private teamJigsLoadConfig: LoadTenantJigsPayload = cloneDeep(DataTableConfig.jigsList.loadConfig.team)
  private sourceJigList: JigMetadata[] = []
  private jigList: JigMetadata[] = []
  private rowsSelected: any = []
  private rowsSelectedJigs: any = []
  private selectedShareOption: StandardObject = {
    text: '',
    filters: {},
    value: '',
  }
  private filterRemovedUsers: { [key: string]: TenantUser } = {}

  @Watch('myTenant')
  private onMyTenantChanged(value: Tenant) {
    if (this.initializing) {
      return
    }

    this.initJigsSourceData()
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(newRoute: any) {
    // As route change watcher happens before created hook, move initialise functions to here.
    // We need to reload jigs as myJigs and teamJigs are from different API Endpoint and also they display different headings/values

    if (this.myTenant.ID === TenantHelpers.InvalidTenantID || this.initializing) {
      return
    }

    this.initJigsSourceData()
  }

  @Watch('initializing')
  private onInitializingChanged(value: boolean, oldValue: boolean) {
    if (!value) {
      this.updateDefaultData()
      this.initJigsSourceData()
    }
  }

  protected mounted() {
    this.myJigsLoadConfig.filters[JigConst.JigListQueryFilters.ownerUserId].value = this.$auth0.uid
    this.updateDefaultData()
  }

  private get searchEvents(): any {
    return UtilsConst.searchEvents
  }

  private get isTeamJigView(): boolean {
    if (this.$route.meta === undefined) {
      return false
    }
    return this.$route.meta.teamJigView || false
  }

  private get totalJigCount(): number {
    if (this.isTeamJigView && this.selectedShareOption.value === '') {
      return this.myTenantTotalJigCount
    } else if (this.isTeamJigView && this.selectedShareOption.value === 'shared') {
      return this.myTenantSharedJigCount
    } else if (this.isTeamJigView && this.selectedShareOption.value === 'noneShared') {
      return this.myTenantPrivateJigCount
    } else {
      return this.myJigCount
    }
  }

  private get sortDefaultSetting(): StandardObject {
    return this.isTeamJigView ? DataTableConfig.jigsList.loadConfig.team.sort : DataTableConfig.jigsList.loadConfig.my.sort
  }

  private get filterRemovedUsersArray(): TenantUser[] {
    return Object.values(this.filterRemovedUsers)
  }

  private get jigListKey(): JigsLoadConfigKey {
    return this.isTeamJigView ? `teamJigsLoadConfig` : 'myJigsLoadConfig'
  }

  private get currentPage(): number {
    return this.isTeamJigView ? this.teamJigsListCurrentPage : this.myJigsListCurrentPage
  }

  // Update Jigs Listing default items per page for JigStaff
  private updateDefaultData() {
    if (TenantHelpers.IsSuperUserOrJigStaff()) {
      this.updateMyJigsItemsPerPage(UtilsConst.defaultJigStaffJigsPerPage)
      this.updateTeamJigsItemsPerPage(UtilsConst.defaultJigStaffJigsPerPage)
    }
  }

  private initializeData() {
    this.filterRemovedUsers = {}
    if (this.isTeamJigView) {
      this.teamJigsLoadConfig.limit = this.jigListItemsPerPageOptions[0]
      // For TeamAdmin users, show all Jigs in tenant,
      // for none TeamAdmin users, only show Jigs that shared with tenant
      this.selectedShareOption =
        TenantHelpers.IsTeamAdmin(this.currentSignedInUser) || this.isSuperUser
          ? DataTableConfig.jigsList.loadConfig.shareOptions[0]
          : DataTableConfig.jigsList.loadConfig.shareOptions[1]
    } else {
      this.selectedShareOption = DataTableConfig.jigsList.loadConfig.shareOptions[1]
      this.myJigsLoadConfig.limit = this.jigListItemsPerPageOptions[0]
    }
    if (this.jigListShareHeaders.views.sort) {
      this.jigListShareHeaders.views.sortFunc = (item: JigMetadata) => (item.Views === this.$auth0.user.email ? 'You' : item.Views)
    }
    if (this.teamJigsOnlyHeaders.owner.sort) {
      this.teamJigsOnlyHeaders.owner.sortFunc = (item: JigMetadata) => item.Uid
    }
    if (this.teamJigsOnlyHeaders.teamVisibility.sort) {
      this.teamJigsOnlyHeaders.teamVisibility.sortFunc = (item: JigMetadata) =>
        this.getTeamVisibilities(item.Tenants as TenantJigPermissions[])
    }
  }

  private extractRemovedUsers() {
    this.jigList.forEach((jig: JigMetadata) => {
      if (this.filterRemovedUsers[jig.Uid] == null && this.tenantUsers.findIndex((user: TenantUser) => user.Uid === jig.Uid) === -1) {
        // Make sure fitlerRemovedUsers is reactive
        Vue.set(this.filterRemovedUsers, jig.Uid, {
          ...TenantConst.defaultTenantUser,
          tenantId: this.myTenant.ID,
          Uid: jig.Uid,
          name: 'N/A',
          email: 'N/A',
        })
      }
    })
  }

  private async loadSourceJigs(filterRequired: boolean = true) {
    this[this.jigListKey].page = this.currentPage
    this[this.jigListKey].limit = this.itemsPerPage

    if (this.isTeamJigView) {
      // TeamJigs view
      this.teamJigsLoadConfig.filters = { ...this.selectedShareOption.filters }
      await this.loadTeamJigs(this.teamJigsLoadConfig)
      this.jigList = [...this.teamJigs]
    } else {
      // MyJigs view
      await this.loadMyJigs({
        requestUserId: this.$auth0.uid,
        loadConfig: this.myJigsLoadConfig,
        sharedWithUser: true,
      })

      this.jigList = [...this.myJigs]
    }

    this.sourceJigList = this.jigList
    // Clear selected items
    if (this.$refs.jigListing) {
      this.$refs.jigListing.UnselectAllItems()
    }
    this.rowsSelected = []
    this.rowsSelectedJigs = []

    this.extractRemovedUsers()

    if (filterRequired) {
      this.filterBySearchCriteria()
    }
  }

  private updateSelectedJigId(id: string = '') {
    this.selectedJigId = id
  }

  private async initJigsSourceData() {
    if (this.isPreparingData) {
      return
    }
    this.isPreparingData = true
    this.initializeData()
    this.updateSelectedJigId()
    this.updateTableHeaders()
    await this.loadSourceJigs(true)
    // The selection value of the checkboxes is bound to both a :v-model of jigs[] (myJigs) and also a :value of an individual jig existing in that array.
    // If the jig load happens after the state is set once and updated, there seems to be a problem with re-loading the myJigs, which will check all the boxes.
    // So we uncheck them here after loading.
    this.isPreparingData = false
  }

  private selectAllRowsChanged(payload: any) {
    this.rowsSelected = payload.rowsSelected
    this.rowsSelectedJigs = payload.rowsSelectedItems
  }

  private onJigSelectChange(payload: any) {
    this.rowsSelected = payload.rowsSelected
    this.rowsSelectedJigs = payload.rowsSelectedItems
  }

  private updateTableHeaders() {
    this.jigListHeadings = {
      ...this.jigListShareHeaders,
    }

    if (this.isTeamJigView) {
      this.jigListHeadings = {
        ...this.jigListHeadings,
        ...this.teamJigsOnlyHeaders,
      }

      delete this.jigListHeadings.views
    }

    this.jigListHeadings = {
      ...this.jigListHeadings,
      ...this.jigListActionHeader,
    }

    const defaultSortBy = this.jigListHeadings[this.sortDefaultSetting.byId]
    if (defaultSortBy.sort) {
      defaultSortBy.isAsc = this.sortDefaultSetting.value === JigConst.jigsListSortTerm.asc

      this.resetDefaultSort(defaultSortBy)
    }
  }

  private resetDefaultSort(dataTableSort: any) {
    if (dataTableSort.sortByQuery) {
      this[this.jigListKey].sort.by = dataTableSort.sortByQuery
      this[this.jigListKey].sort.value = dataTableSort.isAsc ? DataTableConfig.sortTerm.asc : DataTableConfig.sortTerm.desc
    }

    if (this.$refs.jigListing) {
      this.$refs.jigListing.ResetDefaultSortBy()
    }
  }

  private showInviteNewUsersPrompt() {
    if (this.rowsSelected.length === 0) {
      return
    }

    const jigCount = this.rowsSelected.length
    this.selectedJigName = this.rowsSelectedJigs[0].name
    if (jigCount === 2) {
      this.selectedJigName = this.selectedJigName + ' + 1 other Jig'
    } else {
      this.selectedJigName = this.selectedJigName + ' + ' + (jigCount - 1) + ' other Jigs'
    }

    this.$refs.jigActionModals.ShowInviteNewUsersPrompt(this.rowsSelectedJigs)
  }

  private onUpdateJigShareConfig(payload: any) {
    this.permissionsDirty = payload.permissionsDirty
  }

  private isUnlistedJig(visibility: string) {
    return visibility === JigConst.JigVisibilities.Link
  }

  private onUpdatingJigVisibility(payload: JigVisibilityEventData) {
    {
      if (payload.teamSharePermissions != null) {
        this.originalJigTeamSharePermissions = this.jigMetadata.Tenants ? [...this.jigMetadata.Tenants] : undefined
        this.isTeamShareConfigChange = payload.teamSharePermissions != null

        this.craftJigTeamSharingData(payload)
      }
    }
  }

  private onSharePromptClose(payload: any) {
    if (payload.updateResults != null) {
      this.permissionsDirty = true
    }

    this.onPermissionPromptClosed()
  }

  private onPermissionPromptClosed() {
    // deselect all selected jigs
    if (this.$refs.jigListing) {
      ;(this.$refs.jigListing as any).UnselectAllItems()
    }
    this.rowsSelected = []
    this.rowsSelectedJigs = []
    this.loadMyJigsIfPermissionsDirty()
  }

  private onLoadJigsWithCondition(reloadRequired: any) {
    if (reloadRequired !== null) {
      this.permissionsDirty = reloadRequired
    }
    this.loadMyJigsIfPermissionsDirty()
  }

  private async loadMyJigsIfPermissionsDirty() {
    if (this.permissionsDirty) {
      this.permissionsDirty = false
      await this.loadSourceJigs()
    }
  }

  private isShareDisabled(jig: JigMetadata) {
    if (this.isOwner(jig.Uid) || !this.isTeamJigView || this.canEditOrDeleteJig(jig)) {
      // if you are the jig owner, or jig editor, or you are on "My Jigs" page, you can share regardless.
      return false
    } else {
      // If you are not the owner and you are on team share page, you only can share if `shareable-readonly` permission is available.
      return !(jig.Tenants && this.checkTeamShareSetting(jig.Tenants, this.teamSharePermissionsReshareValue))
    }
  }

  private getTeamSharePermissions(tenants: TenantJigPermissions[]) {
    if (!tenants || tenants.length === 0) {
      return null
    }

    return tenants.find((tenant) => tenant.TenantID === this.myTenant.ID && tenant.Type === JigConst.JigTenantsType.share)
  }

  private checkTeamShareSetting(tenants: TenantJigPermissions[], permission: JigConst.SharePermission) {
    if (!this.isTeamJigView) {
      return true
    } else if (!tenants || tenants.length === 0) {
      return false
    }

    const teamSharePermissions = this.getTeamSharePermissions(tenants)

    return teamSharePermissions ? teamSharePermissions.Permissions.includes(permission) : false
  }

  private getTeamVisibilities(tenants: TenantJigPermissions[]) {
    const teamSharePermissions = this.getTeamSharePermissions(tenants)

    if (teamSharePermissions == null) {
      return ''
    }

    let keyPermission = JigConst.SharePermission.view

    if (teamSharePermissions.Permissions.includes(JigConst.SharePermission.edit)) {
      keyPermission = JigConst.SharePermission.edit
    } else if (teamSharePermissions.Permissions.includes(JigConst.SharePermission.reshare)) {
      keyPermission = JigConst.SharePermission.reshare
    }

    return JigConst.teamRights[keyPermission]
  }

  private get itemsPerPage(): number {
    return this.isTeamJigView ? this.teamJigsItemsPerPage : this.myJigsItemsPerPage
  }

  private filterBySearchCriteria() {
    this.jigList = this.sourceJigList.filter((jig: JigMetadata) => {
      let isAMatch: boolean = false
      const isJigOwnerSelected: boolean =
        this.selectedUsers.length === this.tenantUsers.length + this.filterRemovedUsersArray.length ||
        this.selectedUsers.findIndex((user: TenantUser) => user.Uid === jig.Uid) !== -1

      if (isJigOwnerSelected) {
        isAMatch = true
      }

      this.selectedUsers.findIndex((user: TenantUser) => user.Uid === jig.Uid)

      if (this.searchCriteria) {
        const jigName = jig.ProjectName.toLowerCase()
        const jigDescription = jig.ProjectDescription.toLowerCase()
        const keyword = this.searchCriteria.toLowerCase()

        if (jigName.includes(keyword) || jigDescription.includes(keyword)) {
          isAMatch = isAMatch && true
        } else if (jig.Tags.length && jig.Tags.find((tag) => tag.toLowerCase().includes(keyword))) {
          isAMatch = isAMatch && true
        } else {
          isAMatch = isAMatch && false
        }
      }

      return isAMatch
    })
  }

  private searchJigs(searchCriteria: string) {
    this.searchCriteria = searchCriteria
    this.filterBySearchCriteria()
  }

  // Disabled when user is:
  // 1. Not owner of the Jig and,
  // 2. user is not a team admin,
  private dataSelectDisableCheck(item: JigMetadata) {
    return !this.isOwner(item.Uid) && !this.isTeamManagerOfJigOwnerTenant(item.OwnerTenantID)
  }

  private resetJigList() {
    this.searchCriteria = ''
    this.filterBySearchCriteria()
  }

  private updateCurrentPage(page: number) {
    if (this.isTeamJigView) {
      this.updateTeamJigsListCurrentPage(page)
    } else {
      this.updateMyJigsListCurrentPage(page)
    }
  }

  private onItemsPerPageChange(payload: any) {
    this.updateCurrentPage(payload.currentPage)

    if (this.isTeamJigView) {
      this.updateTeamJigsItemsPerPage(payload.newItemsPerPage)
    } else {
      this.updateMyJigsItemsPerPage(payload.newItemsPerPage)
    }
    this.loadSourceJigs()
  }

  private onJigListPageChange(newPage: number) {
    this.updateCurrentPage(newPage)
    this.loadSourceJigs()
  }

  private onSortChange(sortPayload: StandardObject) {
    if (sortPayload.sorted) {
      return
    }

    if (sortPayload.sortByQuery) {
      this[this.jigListKey].sort.by = sortPayload.sortByQuery
      this[this.jigListKey].sort.value = sortPayload.isSortingAsc ? DataTableConfig.sortTerm.asc : DataTableConfig.sortTerm.desc
    }

    this.loadSourceJigs(true)
  }

  private onShareOptionChange(payload: StandardObject) {
    this.selectedShareOption = { ...payload }

    this.loadSourceJigs()
  }

  private onSelectedUserChange(payload: TenantUser[]) {
    if (this.isPreparingApp) {
      return
    }

    this.selectedUsers = [...payload]
    this.filterBySearchCriteria()
  }

  private onFiltersReset(payload: { selectedShareOption: StandardObject; selectedUsers: TenantUser[] }) {
    // Share option is only available for Team Admin, make sure it's only included for TeamAdmins.
    if (TenantHelpers.IsTeamAdmin(this.currentSignedInUser)) {
      this.selectedShareOption = { ...payload.selectedShareOption }
    }
    this.selectedUsers = [...payload.selectedUsers]
    // Prevent doubled up request on router change.
    if (this.isPreparingData) {
      return
    }
    this.loadSourceJigs()
  }
}
